@import '../../../../styles/utils/variables';

.footerContainer {
  margin: 0 !important;
}

.footerText {
  color: #ffffff;
  font-size: 20px;
  line-height: 34.5px;
  text-transform: uppercase;
  font-weight: bold;
}

.footerTextHelp {
  color: #ffffff;
  font-size: 20px;
  line-height: 34.5px;
  text-transform: uppercase;
  font-weight: 400;
}

.footerLogoContainer {
  margin-top: 24px;
}

.footerDivider {
  background-color: #ffffff;
  font-size: 30px;
  margin: 0 18px !important;
}
