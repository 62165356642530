@import '../../../../styles/utils/variables';

.headerContainer {
  padding: 12px 110px;
  border-bottom: 1px solid #C6CCD8;
}

.headerTitle {
  margin: 0 !important;
  color: $primary-color !important;
}

.headerDivider {
  background-color: #000;
  font-size: 30px;
  margin: 0 18px !important;
}