// -----------------------------------------
// Fonts
// -----------------------------------------

// $font-family-1: 'ALSHauss', 'Helvetica', 'Arial', sans-serif;
// $font-family-2: 'Material Icons';

// $font-regular: 400;
// $font-medium: 500;
// $font-bold: 600;

// -----------------------------------------
// Colors
// -----------------------------------------

$primary-color: #0061FF;
$secondary-color: green;

$background-color: #F6F6F6;

// -----------------------------------------
// Sizes
// -----------------------------------------



// -----------------------------------------
// Text sizes
// -----------------------------------------



// -----------------------------------------
// Title sizes
// -----------------------------------------
