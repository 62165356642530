.articleListTitleContainer {
  padding: 6px 12px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.06);
}

.articleAuthorContainer {
  color: #0B0B0C;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.5%; /* 21.36px */
  text-transform: capitalize;
  padding: 16px 14px;
}

.articleFolderIcon {
  margin-right: 16px;
}

.articleItemContainer {
  color: #61637D;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  padding: 13px 16px;
  border-top: 1px solid #C6CCD8;
}