@import "../../../node_modules/normalize-scss/sass/normalize/import-now";
@import './utils/variables';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
  font-family: 'Lato', sans-serif;
}

.ant-select-selector {
  background-color: transparent !important;
}